<template>
  <div class="referrals" :style="pages > 1 ? 'padding-bottom: 0;' : ''">
    <div class="filters">
      <div class="search">
        <label for="search">Cerca</label>
        <input id="search" type="text" name="" placeholder="Nome, Cognome, Azienda, Campagna, Utente CVing" @input="filterSearch($event)">
      </div>

      <div class="date-box">
        <label for="date-from">Data inizio</label>
        <input id="date-from" type="date" @input="dateFromChanged($event)">
      </div>

      <div class="date-box">
        <label for="date-to">Data fine</label>
        <input id="date-to" type="date" @input="dateToChanged($event)">
      </div>

      <div class="lens">
        <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
      </div>
    </div>
    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th v-for="header of headers" :key="header" class="orderable" @click="changeOrder(header.field)">
              <div class="">
                <span>{{ header.title }}</span>
                <font-awesome-icon v-if="(filters.order.field == header.field)" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="row in getFilteredRows()" :key="row.id">
            <td style="text-align:center" class="cursor-pointer dot-container" @click="openQCTooltip(row.referral)">
              <div v-if="row.referral.cvAnonId?.qcState != null" class="dot" :class="'dot-color' + row.referral.cvAnonId.qcState" />
            </td>

            <td>
              <div>
                <Tooltip :text="(row.referral.nameRefferedCandidate + ' ' + row.referral.lastnameRefferedCandidate)" :disabled="(row.referral.nameRefferedCandidate + ' ' + row.referral.lastnameRefferedCandidate).length < 26">
                  <span>
                    {{ getCandidateName(row.referral.nameRefferedCandidate, row.referral.lastnameRefferedCandidate) }}
                  </span>
                </Tooltip>
              </div>
              <div style="margin-top:5px">
                <Tooltip :text="row.referral.mailRefferedCandidate" :disabled="row.referral.mailRefferedCandidate.length < 26">
                  <span class="text-xs">
                    {{ getName(row.referral.mailRefferedCandidate) }}
                  </span>
                </Tooltip>
              </div>
            </td>

            <!-- Nome cognome specificati al suggerimento dal suggeritore -->
            <td>
              <div>
                <Tooltip :text="(row.referredName + ' ' + row.referredSurname)" :disabled="(row.referredName + ' ' + row.referredSurname).length < 21">
                  <span>
                    {{ getCandidateName(row.referredName, row.referredSurname) }}
                  </span>
                </Tooltip>
              </div>
              <div style="margin-top:5px">
                <Tooltip :text="row.referredEmail" :disabled="row.referredEmail.length < 21">
                  <span class="text-xs">
                    {{ getName(row.referredEmail) }}
                  </span>
                </Tooltip>
              </div>
            </td>

            <td>
              <div>
                <slot v-if="row.referral.referralWorkitem?.actionType !== 'candidate_rejected'">
                  <p>
                    {{ dayjs.utc(row.applicationDate, 'YYYY-MM-DDTHH:mm:ss').local().format('DD MMMM YYYY') }}
                  </p>
                  <p style="font-size:13px">
                    {{ dayjs.utc(row.applicationDate, 'YYYY-MM-DDTHH:mm:ss').local().format('HH:mm:ss') }}
                  </p>
                </slot>
                <slot v-else>
                  <!-- {{ getActionDataDate(row) }} -->
                  Rifiutato il {{ dayjs.utc(getActionDataDate(row), 'DD-MM-YYYY').local().format('DD MMMM YYYY') }}

                  <p v-if="dayjs.utc(getActionDataDate(row), 'DD-MM-YYYY HH:mm').isValid()" style="font-size:13px">
                    {{ dayjs.utc(getActionDataDate(row), 'DD-MM-YYYY HH:mm').local().format('HH:mm:ss') }}
                  </p>
                </slot>
              </div>
            </td>

            <td><span v-text="getName(row.referral.companyName)" /></td>

            <!-- Campagna -->
            <td><span class="campaign-link" @click="openInNewTab(row.landingPage)">{{ getName(row.referral.campaignName) }}</span></td>

            <td class="file-link">
              <div class="inline-row" @click.stop.prevent="downloadPDF(row.referral.cid, row.referral.uidReferred, 0)">
                <svg style="width:20px;height:20px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z" />
                </svg>
                <span class="text-underline"> {{ getCVName(row.referral.lastnameRefferedCandidate, 'cv') }} </span>
              </div>
            </td>
            <td class="file-link">
              <div v-if="row.referral.cvAnonId.dateUploadAnonCv">
                <div class="inline-row" @click.stop.prevent="downloadPDF(row.referral.cid, row.referral.uidReferred, 1)">
                  <svg style="width:20px;height:20px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M17.06 13C15.2 13 13.64 14.33 13.24 16.1C12.29 15.69 11.42 15.8 10.76 16.09C10.35 14.31 8.79 13 6.94 13C4.77 13 3 14.79 3 17C3 19.21 4.77 21 6.94 21C9 21 10.68 19.38 10.84 17.32C11.18 17.08 12.07 16.63 13.16 17.34C13.34 19.39 15 21 17.06 21C19.23 21 21 19.21 21 17C21 14.79 19.23 13 17.06 13M6.94 19.86C5.38 19.86 4.13 18.58 4.13 17S5.39 14.14 6.94 14.14C8.5 14.14 9.75 15.42 9.75 17S8.5 19.86 6.94 19.86M17.06 19.86C15.5 19.86 14.25 18.58 14.25 17S15.5 14.14 17.06 14.14C18.62 14.14 19.88 15.42 19.88 17S18.61 19.86 17.06 19.86M22 10.5H2V12H22V10.5M15.53 2.63C15.31 2.14 14.75 1.88 14.22 2.05L12 2.79L9.77 2.05L9.72 2.04C9.19 1.89 8.63 2.17 8.43 2.68L6 9H18L15.56 2.68L15.53 2.63Z" />
                  </svg>
                  <span class="text-underline"> {{ getCVName(row.referral.lastnameRefferedCandidate, 'anonimo') }} </span>
                </div>
              </div>
            </td>
            <td>{{ row.referral.cvAnonId.uidAnomizer?.firstName }} {{ row.referral.cvAnonId.uidAnomizer?.lastName }}</td>
            <td>{{ row.referral.cvAnonId.uidPublisher?.firstName }} {{ row.referral.cvAnonId.uidPublisher?.lastName }}</td>

            <td style="text-align:center">
              <font-awesome-icon v-if="row.referral.state == 'unlock'" icon="check" style="color: lightgreen" />
            </td>

            <td>
              <div>
                <Tooltip :text="(row.referralName + ' ' + row.referralSurname)" :disabled="(row.referralName + ' ' + row.referralSurname).length < 21">
                  <span>
                    {{ getCandidateName(row.referralName, row.referralSurname) }}
                  </span>
                </Tooltip>
              </div>
              <div style="margin-top:5px">
                <Tooltip :text="row.referralEmail" :disabled="row.referralEmail.length < 21">
                  <span class="text-xs">
                    {{ getName(row.referralEmail) }}
                  </span>
                </Tooltip>
              </div>
            </td>
            <td>
              <div v-html="convertDate(row.referral.creationDate)" />
            </td>
            <td class="rejected" :class="{ 'cursor-pointer': isRowRejected(row) }" @click="openRejectedInfoModal(row.referral.referralWorkitem)">
              <font-awesome-icon v-if="isRowRejected(row)" icon="check" style="color: orangered" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="pages > 1" class="pagination">
      <div>Visualizzati {{ numberOfElements }} di {{ totalElements }}</div>
      <v-pagination v-model="page" :pages="pages" active-color="#bcde42" @update:modelValue="getReferrals" />
    </div>
  </div>

  <Dialog ref="pdfPreviewDialog" :pdf-url="pdfUrlToOpen" size="preview" />

  <div v-show="qcTooltipVisible && qcReferralSelected" class="dialog">
    <div class="dialog-content" :class="size">
      <div class="close" @click="closeQCTooltip()">
        x
      </div>
      <div class="dialog-text" style="margin-top: 0;">
        <div titolo class="title">
          Quality Control
        </div>
        <div v-if="qcReferralSelected?.cvAnonId?.uidQc" class="subtitle">
          Ultima modifica: {{ qcReferralSelected?.cvAnonId?.uidQc?.email }}
        </div>
        <div class="subtitle" style="margin-top: 2em;">
          <v-select
            v-model="qcTooltipStateSelected"
            class="inputMaterial"
            :options="qcTooltipStateOptions"
            label="description"
            :reduce="(obj) => obj.value"
            :clearable="false"
          />
        </div>
      </div>
      <button class="button" @click="saveQCTooltip()">
        Aggiorna
      </button>
    </div>
  </div>

  <div v-show="rejectSuggestionModal !== 'hidden'" class="dialog">
    <div class="dialog-content">
      <form class="flex flex-column gap-1 custom-inputs" autocomplete="off" @submit.prevent @keydown.enter.prevent="">
        <strong v-if="rejectSuggestionModal === 'readonly'" style="margin-bottom: 1rem">Rifiutato da {{ rejectSuggestionUser }} - {{ rejectSuggestionDate }} </strong>
        <strong v-else style="margin-bottom: 1rem">Rifiuta suggerimento: </strong>

        Messaggio per il suggeritore:
        <div class="flex">
          <div class="flex-1">
            <textarea
              v-model="rejectSuggestionMessageReferral"
              placeholder="Aggiungi un messaggio per il suggeritore"
              :class="{ 'textarea-border-red': rejectSuggestionValidation }"
              rows="3"
              class="w-full"
              :disabled="rejectSuggestionModal === 'readonly'"
              @keydown.enter.prevent
            />
          </div>
        </div>

        Messaggio per il suggerito:
        <div class="flex">
          <div class="flex-1">
            <textarea
              v-model="rejectSuggestionMessageReferred"
              placeholder="Aggiungi un messaggio per il suggerito"
              :class="{ 'textarea-border-red': rejectSuggestionValidation }"
              rows="3"
              class="w-full"
              :disabled="rejectSuggestionModal === 'readonly'"
              @keydown.enter.prevent
            />
          </div>
        </div>

        <div v-if="rejectSuggestionModal == 'readonly'" class="flex gap-1 justify-space-between">
          <div>
            <p>Azione suggerito:</p>
            <p>{{ rejectSuggestionAction }}</p>
          </div>

          <material-button text="CHIUDI" type="light" @click="rejectSuggestionModal = 'hidden'" />
        </div>
      </form>
    </div>
  </div>

  <floating-button :menu="false" :tooltip="'Scarica i report'" @action="getCSV()" />
</template>

<script>
import Dialog from '../components/Dialog.vue'
import Tooltip from '../components/Tooltip'
import { sbapibackoffice, reportbackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { reactive, ref } from '@vue/reactivity'
import { inject, onMounted } from '@vue/runtime-core'
import router from './../router'

import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'

import dayjs from 'dayjs'
require('dayjs/locale/it')
dayjs.locale('it')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  components: {
    Dialog,
    Tooltip,
    VPagination
  },
  setup() {
    const referrals = ref([])
    const filters = reactive({
      order: {
        field: 'applicationDate',
        asc: false
      },
      search: '',
      dateFrom: null,
      dateTo: null
    })
    let pdfUrlToOpen = ref(null)
    const headers = [
      { title: 'Q.C.', field: '' },
      { title: 'Dati invito', field: 'referralName' },
      { title: 'Dati candidatura', field: '' },
      { title: 'Data candidatura', field: 'applicationDate' },
      { title: 'Azienda', field: 'company_name' },
      { title: 'Campagna', field: 'campaign_name' },
      { title: 'CV caricato', field: '' },
      { title: 'CV anonimizzato', field: '' },
      { title: 'Anonimizzato da', field: '' },
      { title: 'Pubblicato da', field: '' },
      { title: 'Sbloccato', field: '' },
      { title: 'Suggerito da', field: '' },
      { title: 'Data suggerimento', field: '' },
      { title: 'Rifiutato', field: '' }
    ]
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const toast = inject('$toast')

    const rejectSuggestionModal = ref('hidden')
    const rejectSuggestionValidation = ref(false)
    const rejectSuggestionMessageReferral = ref('')
    const rejectSuggestionMessageReferred = ref('')
    const rejectSuggestionUser = ref('')
    const rejectSuggestionDate = ref('')
    const rejectSuggestionAction = ref('')

    // ref to the dialog component
    const pdfPreviewDialog = ref('pdfPreviewDialog')

    const getCSV = () => {
      spinner.show()
      reportbackoffice.get('/candidates/csv', {
        withCredentials: true,
        params: {
          reportType: 'anonymized_candidates',
          startDate: filters.dateFrom ? dayjs(filters.dateFrom).format('YYYY-MM-DD') : '',
          endDate: filters.dateTo ? dayjs(filters.dateTo).format('YYYY-MM-DD') : '',
          search: filters.search || ''
        }
      })
        .then((response) => {
          console.log(response)

          // respons is pure csv, i need to download the file
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'candidates.csv'
          link.click()
        })
        .catch((error) => {
          toast.error('Qualcosa è andato storto, ', error)
        })
        .finally(() => spinner.hide())
    }

    /**
     * Set the order condition for the table
     * @param {number} orderBy - the column to order by
     */
    const changeOrder = (orderBy) => {
      if (filters.order.field == orderBy) {
        filters.order.asc = !filters.order.asc
      } else {
        filters.order.field = orderBy
        filters.order.asc = true
      }

      getReferrals()
    }

    const openInNewTab = (link) => {
      window.open(link, '_blank')
    }

    // used to manage the search input debounce time
    const typingTimer = ref(null)

    /**
     * manage the search input
     * @param {Event} target - the oninput input event
     */
    const filterSearch = ({ target }) => {
      filters.search = target.value

      // execute getReferrals after 500ms of non typing
      clearTimeout(typingTimer.value)
      typingTimer.value = setTimeout(() => {
        page.value = 1
        getReferrals()
      }, 1000)
    }

    const getFilteredRows = () => {
      const rows = referrals.value
      return rows
    }

    const convertDate = (date) => {
      return dayjs.utc(date, 'YYYY-MM-DDTHH:mm:ss').local().format('DD MMMM YYYY') + '<p style="font-size:13px">' + dayjs.utc(date, 'YYYY-MM-DDTHH:mm:ss').local().format('HH:mm:ss') + '</p>'
    }

    const dateFromChanged = ({ target }) => {
      filters.dateFrom = target.value ? new Date(target.value) : undefined
      page.value = 1
      getReferrals()
    }

    const dateToChanged = ({ target }) => {
      filters.dateTo = target.value ? new Date(target.value) : undefined
      page.value = 1
      getReferrals()
    }

    const downloadPDF = (cid, uid, type = 0) => {
      spinner.show()
      sbapibackoffice
        .get('/referred/document/url', {
          withCredentials: true,
          params: {
            cid,
            uid,
            type
          }
        })
        .then((response) => {
          pdfUrlToOpen.value = response.data
          pdfPreviewDialog.value.open(pdfUrlToOpen.value)
        })
        .catch((error) => {
          toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => spinner.hide())
    }

    const getCandidateName = (name, surname) => {
      let n = name + ' ' + surname
      if (n.length > 25) {
        n = n.slice(0, 22)
        n = n + '...'
      }
      return n
    }
    const getCVName = (name, type) => {
      let c = name
      if (c.length > 10) {
        c = c.slice(0, 7)
        if (type === 'cv') {
          c += '...CV'
        } else {
          c += '...anonCV'
        }
      } else {
        if (type === 'cv') {
          c += '.CV'
        } else {
          c += '.anonCV'
        }
      }
      return c
    }
    const getName = (name) => {
      let c = name
      if (c.length > 20) {
        c = c.slice(0, 17)
        c += '...'
      }
      return c
    }

    onMounted(() => {
      navbar.title = 'Archivio'
      getReferrals()
    })

    const getReferrals = () => {
      spinner.show()
      sbapibackoffice
        .get('/referral/checkanon', {
          withCredentials: true,
          params: {
            page: page.value - 1,
            size: 10,
            sort: `${filters.order.field},${filters.order.asc ? 'asc' : 'desc'}`,
            search: filters.search || undefined,
            startDateFilter: filters.dateFrom ? dayjs(filters.dateFrom).format('YYYY-MM-DD') : undefined,
            endDateFilter: filters.dateTo ? dayjs(filters.dateTo).format('YYYY-MM-DD') : undefined
          }
        })
        .then(({ data }) => {
          referrals.value = []
          referrals.value = data.content
          // Object.assign(referrals, [])
          // Object.assign(referrals, data.content)

          pages.value = data.totalPages
          totalElements.value = data.totalElements
          numberOfElements.value = data.numberOfElements
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              router.push({ path: '/login' })
            }
            toast.error(error.response.data.description)
          } else {
            toast.error('Qualcosa è andato storto')
          }
        })
        .then(() => spinner.hide())
    }

    const openQCTooltip = (referral) => {
      console.log(referral)
      qcTooltipVisible.value = true
      qcReferralSelected.value = referral
      qcTooltipStateSelected.value = referral.cvAnonId?.qcState || 0
    }

    let qcTooltipVisible = ref(false)
    let qcTooltipStateSelected = ref(0)
    let qcTooltipStateOptions = [{ value: 0, description: 'Non ancora valutato' }, { value: 1, description: 'In linea' }, { value: 2, description: 'Non in linea' }]
    let qcReferralSelected = ref(null)
    const closeQCTooltip = () => (qcTooltipVisible.value = false)
    const saveQCTooltip = () => {
      spinner.show()
      sbapibackoffice
        .put('/anonymized/qualitycontrol', {
          idCvAnonimized: qcReferralSelected.value.cvAnonId.id,
          qcState: qcTooltipStateSelected.value
        })
        .then(({ data }) => {
          if (data.response == 'OK') {
            toast.success('Salvataggio effettuato')
            qcTooltipVisible.value = false
            // update changed referral
            const referral = referrals.value.find((r) => r.referral.id == qcReferralSelected.value.id)
            console.log(referral)
            referral.referral.cvAnonId.qcState = qcTooltipStateSelected.value
          } else {
            toast.error('Qualcosa è andato storto')
          }
        })
        .catch((error) => {
          if (error) {
            toast.error(error)
          } else {
            toast.error('Qualcosa è andato storto')
          }
        })
        .then(() => spinner.hide())
    }

    const isRowRejected = (row) => {
      return row.referral.referralWorkitem && row.referral.referralWorkitem.actionType !== 'candidate_suggested'
    }

    /**
     * Open the modal to show the rejected info
     * @param {string} actionData object stringified with the data to show
     */
    const openRejectedInfoModal = ({ actionData, updateDate, wiUser, actionType }) => {
      if (!actionData) return

      const data = JSON.parse(actionData)
      rejectSuggestionMessageReferral.value = data.find(f => f.name === 'messageReferral')?.value
      rejectSuggestionMessageReferred.value = data.find(f => f.name === 'messageReferred')?.value
      rejectSuggestionUser.value = wiUser.firstName + ' ' + wiUser.lastName
      rejectSuggestionDate.value = dayjs(updateDate).format('DD/MM/YYYY HH:mm')

      switch (actionType) {
        case 'rejected': rejectSuggestionAction.value = 'Rifiutato da backoffice'; break
        case 'candidate_confirmed': rejectSuggestionAction.value = 'Candidatura spontanea confermata'; break
        case 'candidate_rejected': rejectSuggestionAction.value = 'Candidatura rifiutata da email'; break
        case 'candidate_suggested': rejectSuggestionAction.value = 'Invito creato'; break
      }

      rejectSuggestionModal.value = 'readonly'
      rejectSuggestionValidation.value = false
    }

    const getActionDataDate = (row) => {
      return JSON.parse(row.referral.referralWorkitem.actionData).find(f => f.name === 'candidate_rejected_data').value
    }

    const page = ref(1)
    const pages = ref(0)
    const totalElements = ref(0)
    const numberOfElements = ref(0)

    return {
      referrals,
      filters,
      pdfUrlToOpen,
      headers,
      spinner,
      navbar,
      changeOrder,
      filterSearch,
      getFilteredRows,
      convertDate,
      dateFromChanged,
      dateToChanged,
      downloadPDF,
      pdfPreviewDialog,
      getCandidateName,
      getName,
      getCVName,
      page,
      pages,
      getReferrals,
      totalElements,
      numberOfElements,
      openQCTooltip,
      qcTooltipVisible,
      closeQCTooltip,
      qcReferralSelected,
      qcTooltipStateOptions,
      qcTooltipStateSelected,
      saveQCTooltip,
      openInNewTab,
      getCSV,
      isRowRejected,
      rejectSuggestionModal,
      rejectSuggestionValidation,
      rejectSuggestionMessageReferral,
      rejectSuggestionMessageReferred,
      rejectSuggestionUser,
      rejectSuggestionDate,
      rejectSuggestionAction,
      openRejectedInfoModal,
      getActionDataDate,
      dayjs
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../assets/scss/referrals';
@use '../assets/scss/table';
@use '../assets/scss/dialog';

.referrals table thead,
.referrals table thead tr:first-child,
.referrals table thead tr:first-child th {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.referrals table tr th:first-child,
.referrals table .table-body tr td:first-child {
  padding-left: 1.5rem !important;
}

.text-xs {
  font-size: 13px;
  margin-top: 4px;
}

.floating-button-container {
  bottom: 65px
}
</style>
<style lang="scss">
.pagination {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul.Pagination {
    justify-content: flex-end;

    li.PaginationControl>path {
      fill: rgba(255, 255, 255, 0.233);
    }

    .Control-active {
      fill: white;
    }

    .Page {
      width: 25px;
      height: 25px;
      color: white;
    }

    button.Page-active {
      background: var(--accented) !important;
      border: 1px solid var(--accented) !important;
      color: #003e73;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.dot-container {
  &:hover {
    scale: 125%;
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;

    &.dot-color0 {
      background-color: #878787;
    }

    &.dot-color1 {
      background-color: #09ff00;
    }

    &.dot-color2 {
      background-color: #ff4500;
    }
  }
}

.campaign-link {
  cursor: pointer;

  text-decoration: underline;
  color: #f4f4f4aa;
}

.subtitle {
  .v-select {
    .vs__dropdown-toggle {
      border: 1px solid #3c3c3c42 !important;

      svg {
        fill: gray !important;

        path {
          fill: gray !important;
          stroke: gray !important;
        }
      }
    }

    .vs__selected {
      color: black !important;
    }
  }

  .vs__dropdown-menu {
    border: 1px solid #3c3c3c42 !important;

    li {
      background: white !important;
      color: black !important;

      &.vs__dropdown-option--highlight {
        background: #5897fb !important;
        color: white !important;
      }
    }
  }
}

.rejected {
  text-align: center;
  font-size: 1.25rem !important;

  &:hover {
    svg {
      scale: 125%;
    }
  }
}

.dialog {
  textarea {
    padding: 5px;
    background-color: #eee;
    border-color: #eee;
    border-radius: 3px;
    outline: none;
    border: none;
    min-width: 300px;
    height: 75px;
    resize: vertical;

    &.textarea-border-red {
      border: 2px solid #e33131;
    }
  }
}
</style>
